<template>
    <div class="Awarp">
        <dashCard class="photovoltaicBox3" :operateWidth="operateWidth" :isLoading="isLoading" :gridOption="gridOption" @changeScreen="changeScreen">
            <template slot="title">{{commonLangHandler('photovoltaicBox3_alarm','当前告警', getZEdata)}}</template>
            <template slot="aside"></template>
            <div class="card-content1">
                <chartBoard ref="chart1" :option="chartOption1"/>
            </div>
            <div class="card-content2">
                <div class="card-list">
                    <div class="card-list-item" v-for="(item,idx) in detailInfo.chart1.seriesData" :key="idx">
                        <span>
                            <i :style="{background: colorList[idx]}"></i>
                            <font>{{ item.name }}</font>
                        </span>
                        <span> {{ item.value }}{{commonLangHandler('photovoltaicBox3_item','项', getZEdata)}} </span>
                        <span> {{ item.percent }}%</span>
                    </div>
                </div>
            </div>
        </dashCard>
        <a-modal v-if="!isModal" class="Amodal" v-model="option.visible" :closable="false" :footer="null" :keyboard="false" width="100%" :dialog-style="{ top: '0px' }">
            <div style="height:calc(100vh)">
                <photovoltaicBox3  :option="option" :isModal="true" :orginSearchObj="searchObj"/>
            </div>
        </a-modal>
    </div>
    </template>
    <script>
    export default {
        name: 'photovoltaicBox3',
        components: {
            dashCard: () => import('@/component/dashCard.vue'),
            chartBoard: () => import('@/component/chartBoard.vue'),
        },
        props: {
            gridOption: {
                type: Object,
                default: function() {
                    return { w: 6, h: 6 }
                }
            },
            option: {
                type: Object,
                default: function() {
                    return {
                        visible: false
                    }
                }
            },
            isModal: {
                type: Boolean,
                default: function() {
                    return false
                }
            },
            orginSearchObj: {
                type: Object,
                default: function() {
                    return {}
                }
            }
        },
        watch: {
            gridOption: {
                handler: function(newVal,oldVal) {
                    this.$nextTick(_=>{
                        this.getOperateWidth()
                        var dom = this.$refs['chart1']
                        if(!!dom) {
                            dom.handleWindowResize()
                        }
                    })
                },
                deep: true,
                immediate: true
            },
            "option.visible": {//全屏化时执行
                handler: function(newVal,oldVal) {
                    if(newVal) {
                        if(this.isModal) {
                            this.searchObj = this.orginSearchObj
                            this.getData()
                        }
                    }
                },
                deep: true,
                immediate: true
            },
        },
        data() {
            return {
                operateWidth: 64, //操作列宽度
                isLoading: false,//数据加载中

                searchObj: {
                    YEAR: '2022',
                    QUARTER: '0',
                },
                chartOption1: {},
                detailInfo: {
                    chart1: {
                        seriesData: []
                    }
                },
                colorList: ["#FF7300","#DBDBDB","#F03040","#D21423"],
                }
        },
        computed: {},
        methods: {
            // 全屏切换
            changeScreen() {
                this.option.visible = !this.isModal
                if(!this.option.visible) {
                    this.getData()
                }
            },
            // 右侧操作列宽度
            getOperateWidth () {
                if(!this.$el.querySelector) {
                    return
                }
                var dom = this.$el.querySelector(".card-head-operate")
                if(!!dom) {
                    var width = dom.clientWidth
                    this.operateWidth = width > 0 ? width + 12 : width
                }
            },
            // 数字转为千位分隔符表示
            toThousandsSeparator: function(value) {
                if(!value) return 0
                // 获取整数部分
                const intPart = Math.trunc(value)
                // 整数部分处理，增加,
                const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
                // 预定义小数部分
                let floatPart = ''
                // 将数值截取为小数部分和整数部分
                const valueArray = value.toString().split('.')
                if (valueArray.length === 2) { // 有小数部分
                    floatPart = valueArray[1].toString() // 取得小数部分
                    return intPartFormat + '.' + floatPart
                }
                return intPartFormat + floatPart
            },

            handleChange(value) {
            console.log("handleChange",value,this.searchObj)
            this.getData()
        },
        //每月报警预警数
        getData() {
            this.isLoading = true
            this.detailInfo = {}
            var chart1 = {
                seriesData: [
                {
                    name: "提示告警",
                    value: 5,
                    percent: 25
                },
                {
                    name: "次要告警",
                    value: 5,
                    percent: 25
                },
                {
                    name: "重要告警",
                    value: 5,
                    percent: 25
                },
                {
                    name: "紧急告警",
                    value: 5,
                    percent: 25
                },
                ]
            }
            this.detailInfo.chart1 = chart1
            this.initEchart()
            this.isLoading = false
        },
		initEchart() {
            var {seriesData} = this.detailInfo.chart1
            var sum = seriesData.reduce(function(prev, cur) {
                        return prev + cur.value;
            }, 0)
            var colorList = this.colorList
            var option = {
                animation:false,
                layoutAnimation:false,
                title: [
                    // {
                    //     text: "总计(项)",
                    //     subtext: sum,
                    //     textStyle: {
                    //         fontSize: 12,
                    //         color: "#1D2129",
                    //         fontFamily: "ABBvoice_WCNSG_Rg"
                    //     },
                    //     subtextStyle: {
                    //         fontSize: 24,
                    //         color: "#1D2129",
                    //         fontWeight: '700',
                    //         fontFamily: "ABBvoice_WCNSG_Rg"
                    //     },
                    //     textAlign: "center",
                    //     left: '50%',
                    //     top: '25%'
                    // },
                    // {
                    //     subtext: "告警总数",
                    //     subtextStyle: {
                    //         fontSize: 12,
                    //         color: "#868686",
                    //         fontFamily: "ABBvoice_WCNSG_Rg"
                    //     },
                    //     textAlign: "center",
                    //     left: '50%',
                    //     top: '45%'
                    // },
                  {
                    text: "",
                    subtext:  sum,
                    textStyle: {
                      fontSize: 12,
                      color: "#86909c",
                      fontFamily: 'ABBvoice_WCNSG_Rg',
                    },
                    subtextStyle: {
                      fontSize: 24,
                      fontFamily: 'ABBvoice_WCNSG_Rg',
                      color: "#1D2129",
                      fontWeight: '600',
                    },
                    textAlign: "center",

                    x: "50%",
                    y: "32%",
                  },
                  {
                    text: "总计(坐)",
                    textStyle: {
                      fontSize: 12,
                      color: "#86909c",
                      fontFamily: 'ABBvoice_WCNSG_Rg',
                    },
                    textAlign: "center",

                    x: "50%",
                    y: "42%",
                  }
                ],
                tooltip: {
                    trigger: 'item'
                },
                series: [{
                    itemStyle: {
                        normal: {
                            color: function (params) {
                                return colorList[params.dataIndex]
                            },
                            borderColor: '#172659',
                            borderWidth: 0
                        }
                    },
                    type: 'pie',
                    radius: ['70%', '80%'],
                    center: ["50%", "40%"],
                    labelLine: {
                        normal: {
                            lineStyle: {
                                color: '#34569D'
                            }
                        }
                    },
                    label: {
                        normal: {
                            show: false,
                            formatter: params => {
                                return (
                                    '{name|' + params.name + '}{percent|' + params.percent.toFixed(0) + '%}'
                                );
                            },
                            padding: [0, -5, 0, -5],
                        }
                    },
                    data: seriesData
                }
                ]
            }

            this.updateChart('chart1', 'chartOption1', option)
        },
        updateChart: function (refName, optionName, option = {}) {
            /* 渲染echart图 */
            if (!optionName) return
            this[optionName] = option
            setTimeout(() => {
                this.$refs[refName].updateChartView()
            }, 500)
        },

        },

        mounted() {
            this.getOperateWidth()
            if(!this.option.visible) {
                this.getData()
            }
        }
    }
    </script>

<style lang="less" scoped>
.photovoltaicBox3 {
    /deep/ .card-content {
        flex-direction: column !important;
    }
    .card-content1 {
        flex: 1;
        height: 100%;
        div{
            width: 100%;
            height: 100%;
        }
    }
    .card-content2 {
            display: flex;
            flex-direction: column;
            height: 176px;
            .card-list-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                span {
                    padding: 4px 0;
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 400;
                    text-align: left;
                    color: #1f1f1f;
                    line-height: 20px;
                    &:nth-child(1) {
                        i {
                            width: 4px;
                            height: 36px;
                            display: inline-block;
                            margin-right: 20px;
                        }
                    }

                }
                &:last-child span{
                    padding-bottom: 0;
                }
            }
        }
}
</style>
